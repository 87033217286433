<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :title="isCreated ? '新增分类' : '编辑分类'"
    :visible.sync="show"
    width="550px"
  >
    <el-form
      v-loading="loading"
      size="small"
      :model="form"
      :rules="rules"
      ref="form"
      label-width="100px"
    >
      <el-form-item label="省" prop="provinceId">
        <el-select v-model="form.provinceId" clearable @change="getCity">
          <el-option
            v-for="item in provinceList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="市" prop="cityId">
        <el-select v-model="form.cityId" clearable @change="getRegion">
          <el-option
            v-for="item in cityList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="区/县" prop="regionId">
        <el-select v-model="form.regionId" clearable multiple>
          <el-option
            v-for="item in regionList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="formSubmit" :loading="btnLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    const rules = {
      provinceId: [
        {
          required: true,
          trigger: 'change',
          message: '请选择省'
        }
      ],
      cityId: [
        {
          required: true,
          trigger: 'change',
          message: '请选择市'
        }
      ],
      regionId: [
        {
          required: true,
          trigger: 'change',
          message: '请选择区/县'
        }
      ]
    }

    return {
      rules,
      provinceList: [],
      cityList: [],
      regionList: [],
      loading: false,
      btnLoading: false,
      form: {
        provinceId: '',
        cityId: '',
        regionId: []
      }
    }
  },
  async created() {
    if (!this.isCreated) {
      this.loading = true
      try {
        await this.getProvince()
        await this.getCity(this.currentItem.provinceId)
        await this.getRegion(this.currentItem.cityId)

        for (let i in this.form) {
          this.form[i] = this.currentItem[i]
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    } else {
      await this.getProvince()
    }
  },

  methods: {
    async getProvince() {
      try {
        const res = await this.$api.common.getProvince()

        this.provinceList = (res.list || []).map(item => ({
          value: item.id,
          label: item.name
        }))
      } catch (e) {
        console.log(e)
      }
    },
    async getCity(val) {
      if (!val) return
      try {
        const res = await this.$api.common.getCity(val)

        this.cityList = (res.list || []).map(item => ({
          value: item.id,
          label: item.name
        }))
      } catch (e) {
        console.log(e)
      }
    },
    async getRegion(val) {
      if (!val) return
      try {
        const res = await this.$api.common.getRegion(val)

        this.regionList = (res.list || []).map(item => ({
          value: item.id,
          label: item.name
        }))
      } catch (e) {
        console.log(e)
      }
    },
    formSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.btnLoading = true

          const sendData = { ...this.form }

          if (!this.isCreated) {
            sendData.id = this.currentItem.id
          }

          try {
            this.isCreated
              ? await this.$api.supplyAreaConfig.areaSave(sendData)
              : await this.$api.supplyAreaConfig.areaUpdate(sendData)
            this.$message.success('操作成功')
            this.$emit('getData')
            this.show = false
          } catch (e) {
            console.log(e)
          } finally {
            this.btnLoading = false
          }
        }
      })
    }
  }
}
</script>
