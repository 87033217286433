<template>
  <page v-loading="loading">
    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="供货区域" prop="areaName">
          <el-input placeholder="省/市/区县" v-model="params.areaName" />
        </el-form-item>
      </el-form>
    </template>

    <template slot="headerRight">
      <el-button type="primary" @click="addHandler">添加区域</el-button>
    </template>

    <div class="table-wrap">
      <el-table :data="tableData" border height="100%" style="width: 100%">
        <el-table-column
          show-overflow-tooltip
          prop="provinceName"
          label="省"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="cityName"
          label="市"
        ></el-table-column>
        <el-table-column show-overflow-tooltip prop="regionName" label="区/县">
          <template slot-scope="{ row }">
            <span>{{ row.regionName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button @click="editHandler(row)" type="text">编辑</el-button>
            <el-button @click="delHandler(row)" type="text">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <AddModal
      @getData="getData(true)"
      v-if="addVisible"
      :visible.sync="addVisible"
      :currentItem="currentItem"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddModal from './components/AddModal'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      currentItem: null,
      addVisible: false,
      tableData: [],
      params: {
        areaName: '',
        limit: 20,
        page: 1
      },
      totalCount: 0
    }
  },
  components: {
    AddModal
  },
  created() {
    this.getData()
  },
  methods: {
    addHandler() {
      this.currentItem = null
      this.addVisible = true
    },
    editHandler(row) {
      this.currentItem = row
      this.addVisible = true
    },
    async delHandler(row) {
      try {
        await this.$confirm(`是否要删除该区域？`, '提示', {
          type: 'warning',
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        })

        try {
          this.loading = true

          await this.$api.classification.areaDelHandler({ id: row.id })
          this.$message.success('删除成功！')
          this.getData(true)
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {}
    },
    async getData(query) {
      this.loading = true

      const sendData = JSON.parse(JSON.stringify(this.params))

      if (query) {
        sendData.page = 1
        sendData.limit = 20
      }

      try {
        const res = await this.$api.supplyAreaConfig.getList(sendData)

        const tableData = res.page.list.map(item => {
          return item
        })

        this.tableData = tableData
        this.totalCount = res.page.totalCount
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
